<template>
    <div class="a-mini-list" v-if="data.length > 0">
        <div
            class="a-mini-list-scrollView"
            :style="{
                width: `calc(${size * data.length}px - 18px )`,
            }"
        >
            <template v-for="(row, key) in data">
                <slot :item="row">
                    <a-mini-item
                        :key="key"
                        :imgUrl="row.teamImgUrl || row.imgUrl1"
                        :genre="row.genre || ''"
                        :name="row.teamName || ''"
                        :size="row.size"
                        :artistidx="row.artistidx"
                        :hashTag="row.hashTag"
                        :reviewCount="row.reviewCount"
                        :favCount="row.favCount"
                        :pointCount="row.pointCount"
                    />
                </slot>
            </template>
        </div>
    </div>
</template>

<script>
import AMiniItem from '@/components/artist/AMiniItem'
export default {
    name: 'AMiniList',
    components: {
        AMiniItem,
    },
    props: {
        data: {
            type: Array,
            default: () => [],
        },
        size: {
            type: Number,
            default: 248,
        },
    },
}
</script>

<style lang="scss" scoped>
.a-mini-list {
    overflow-y: hidden;
    overflow-x: auto;

    &::-webkit-scrollbar {
        display: none;
    }
    .a-mini-list-scrollView {
        width: 224px;
        display: flex;
        flex-direction: row;
        padding-bottom: 10px;

        .a-mini-item {
            margin-right: 18px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}
</style>
