<template>
    <div
        class="ui-badge"
        :class="[type && `ui-badge-${type}`, {
            'ui-badge-left': left,
        }]"
        >
        <slot />
    </div>
</template>

<script>
export default {
    name: 'UiBadge',
    props: {
        type: {
            type: String,
        },
        left: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style lang="scss">
@import '@/assets/css/constant.scss';

.ui-badge {
    margin-right: 0.6rem;
    margin-bottom: 1.5rem;
    width: 5.3rem;
    line-height: 2.8rem;
    height: 2.5rem;
    border-radius: 0.5rem;
    font-size: 1.4rem;
    color: #fff;
    background-color: #979797;
    text-align: center;

    &.ui-badge-left {
        float: left;
    }

    &.ui-badge-end {
        background-color: #979797;
    }
    &.ui-badge-1-1 {
        background-color: #7fdafa;
    }
    &.ui-badge-read {
        background-color: #ffcb39;
    }
    &.ui-badge-new {
        background-color: #ff8f9f;
    }
    &.ui-badge-dDay {
        background-color: #2bc4cc;
    }

    &.ui-badge-artistHot {
        background-color: $color-deepLavender;
    }

    &.ui-badge-artistCheck {
        background-color: #30c39e;
    }

    &.ui-badge-end {
        background-color: #979797;
    }
    &.ui-badge-1-1 {
        background-color: #7fdafa;
    }
    &.ui-badge-read {
        background-color: #ffcb39;
    }
    &.ui-badge-dDay {
        background-color: #2bc4cc;
    }
    &.ui-badge-new {
        background-color: #ff8f9f;
    }
    &.ui-badge-hot {
        background-color: $color-deepLavender;
    }
    &.ui-badge-event-outline {
        color: $color-deepLavender;
        background-color: transparent;
        border: 1px solid $color-deepLavender;
    }
    &.ui-badge-light {
        background-color: #f0f0f0;
        color: #000;
    }
}
</style>
