<template>
    <div
        class="a-mini-item"
        :style="{
            width: `${size}px`,
            height: `${size}px`,
        }"
        @click="() => gotoProfile()"
    >
        <!-- 이미지 영역만 로딩-->
        <PuSkeleton :loading="imageLoading" width="224px" height="172px">
            <div
                class="a-mini-item-img"
                :style="{
                    backgroundImage: imgUrl
                        ? `url(${$lib.cdnUrl(imgUrl)})`
                        : 'none',  
                }"
            />
        </PuSkeleton>
        <div class="a-mini-item-txtzone">
            <div class="a-mini-item-text">
                <span>{{ name }}</span>
                <div class="a-mini-item-category">{{ $lib.t_genre(genre) }}</div>
                <div class="hastag-box-wrap">
                    <span class="hastag-box" v-for="(row, key) in hashTagList" :key="key">{{ row }}</span>
                </div>
                <AEvaluation :reviewCount="reviewCount" :favCount="favCount" :pointCount="pointCount">
                </AEvaluation>
            </div>
        </div>
    </div>
</template>

<script>
import AEvaluation from './AEvaluation.vue'

export default {
    name: 'AMiniItem',
    components: {
        AEvaluation,
    },
    props: {
        artistidx: {
            type: [String, Number],
            required: true,
        },
        imgUrl: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            required: true,
        },
        genre: {
            type: String,
            required: true,
        },
        size: {
            type: Number,
        },
        mainMenuArtistUid: {
            type: [String, Number],
        },
        hashTag: {
            type: String,
            default: ''
        },
        reviewCount: {
            type: String,
            default: ''
        },
        favCount: {
            type: String,
            default: ''
        },
        pointCount: {
            type: String,
            default: ''
        },
    },

    computed: {
        hashTagList() {
            if(this.hashTag) {
                return this.hashTag.split(',')
            }else {
                return [];
            }
            
        }
    },

    data() {
        return {
            imageLoading: false,
        }
    },

    created() {
        this.loadBackgroundImages()
    },

    methods: {
        gotoProfile() {
            if (this.artistidx) {
                this.$router.push({
                    path: `/artist/profile/${this.artistidx}`,
                    query: {
                        main_menu_artist_uid: this.mainMenuArtistUid,
                    },
                })
            }
        },

        loadBackgroundImages() {
            this.imageLoading = true

            const img = new Image()
            img.src = this.$lib.cdnUrl(this.imgUrl)

            img.onload = () => {
                this.imageLoading = false
            }

            img.onerror = (e) => {
                this.imageLoading = false
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/constant.scss';
.a-mini-item {
    position: relative;
    width: 242px;
    height: 321px;
    font-size: 1.8rem;
    overflow: hidden;
    border-radius: 1rem;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    // flex-shrink: 0;

    > span {
        display: block;
        width: 200px;
        .a-mini-item-img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            height: 172px;
            background-size: cover;
            background-position: center;
        }
    }

    .a-mini-item-txtzone {
            position: absolute;
            top: 172px;
            left: 0;
            width: 100%;
            height: 149px;
            padding: 1.4rem;
            background-color: $color-white;

            .a-mini-item-category {
                margin-bottom: 0.7rem;
                color: #333;
                font-size: 1.8rem;
                line-height: 2.5rem;
            }
            .a-mini-item-text {
                overflow: hidden;
                color: #000;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                > span {
                    display: block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 2rem;
                    line-height: 2.8rem;
                    font-weight: 600;
                    white-space: nowrap;
                    margin-bottom: 0.8rem;
                }
            }

            .hastag-box-wrap {
                overflow: hidden;
                margin-top: 6px;
                white-space: nowrap;
                text-overflow: ellipsis;

                .hastag-box {
                    display: inline-block;
                    padding: 1.5px 2.5px;
                    border: 1px solid #ededed;
                    border-radius: 2px;
                    background-color: #f5f5f5;
                    color: #666666;
                    font-size: 1.3rem;
                    & ~ .hastag-box {
                        margin-left: 0.5rem;
                    }
                }
            }
        }
}
</style>
