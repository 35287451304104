var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "a-mini-item",
      style: {
        width: _vm.size + "px",
        height: _vm.size + "px"
      },
      on: {
        click: function() {
          return _vm.gotoProfile()
        }
      }
    },
    [
      _c(
        "PuSkeleton",
        {
          attrs: { loading: _vm.imageLoading, width: "224px", height: "172px" }
        },
        [
          _c("div", {
            staticClass: "a-mini-item-img",
            style: {
              backgroundImage: _vm.imgUrl
                ? "url(" + _vm.$lib.cdnUrl(_vm.imgUrl) + ")"
                : "none"
            }
          })
        ]
      ),
      _c("div", { staticClass: "a-mini-item-txtzone" }, [
        _c(
          "div",
          { staticClass: "a-mini-item-text" },
          [
            _c("span", [_vm._v(_vm._s(_vm.name))]),
            _c("div", { staticClass: "a-mini-item-category" }, [
              _vm._v(_vm._s(_vm.$lib.t_genre(_vm.genre)))
            ]),
            _c(
              "div",
              { staticClass: "hastag-box-wrap" },
              _vm._l(_vm.hashTagList, function(row, key) {
                return _c("span", { key: key, staticClass: "hastag-box" }, [
                  _vm._v(_vm._s(row))
                ])
              }),
              0
            ),
            _c("AEvaluation", {
              attrs: {
                reviewCount: _vm.reviewCount,
                favCount: _vm.favCount,
                pointCount: _vm.pointCount
              }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }