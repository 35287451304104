var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data.length > 0
    ? _c("div", { staticClass: "a-mini-list" }, [
        _c(
          "div",
          {
            staticClass: "a-mini-list-scrollView",
            style: {
              width: "calc(" + _vm.size * _vm.data.length + "px - 18px )"
            }
          },
          [
            _vm._l(_vm.data, function(row, key) {
              return [
                _vm._t(
                  "default",
                  [
                    _c("a-mini-item", {
                      key: key,
                      attrs: {
                        imgUrl: row.teamImgUrl || row.imgUrl1,
                        genre: row.genre || "",
                        name: row.teamName || "",
                        size: row.size,
                        artistidx: row.artistidx,
                        hashTag: row.hashTag,
                        reviewCount: row.reviewCount,
                        favCount: row.favCount,
                        pointCount: row.pointCount
                      }
                    })
                  ],
                  { item: row }
                )
              ]
            })
          ],
          2
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }